import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`How am I to know if it is a revelation? How am I to know if it is true?`}</p>
    </blockquote>
    <p>{`Often times, I think we fall into a sinister and subtle trap regarding truth. We
think we must see it all the way through before we set out on the journey, while
it is the journey that makes us the types of beings that can understand the
truth. We sit and agonize trying to determine whether or not we have received
revelation rather than trusting that we have and that if we haven't and we think
that we have, God will course correct us. He will not let us go long in
falsehood before letting that house of cards topple over. Thank God.`}</p>
    <p>{`When the house of cards topples will it be painful? Of course. That pain is the
raw material on which God can give you understanding. It is His love that allows
us to feel pain, to let the house of cards topple over that we might better
understand the truth.`}</p>
    <p>{`It is better to have our house of cards toppled over than to go on living in a
delusion. What is the alternative? That we think and think and think each
thought strengthening our fears and doubts and thus the less we know what to do!
When it is the doing that reveals the truth. Take faith that right or wrong it
will be consecrated for our good. The faith that a loving Father will reveal to
us our well-intentioned mistakes and let us feel the validation & reward of
truth.`}</p>
    <p>{`It is a sinister trap indeed to be paralyzed in thought and never serving,
learning & growing. It is better to be wrong and corrected moving towards the
light of truth than to be damned without progression in thought.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      